import Section from "@components/Section";
import EventSection from "@components/EventSection";
import HeroSection from "@components/HeroSection";
import { SiteLink, SiteButton } from "@components/SiteClickable";
import { FreshersPictureHeroSection } from "@components/FreshersPictureHeroSection";
import { freshers2020page, freshers2021table, freshers2020freshershacklogo, freshers2020freshershacklogolink, freshers2020capturetheflag, noBottom, freshers2021tableContainer } from "../../../../src/posts/events/freshers2022/index.module.scss";
import logo_var_orange from "../../../../src/posts/events/freshers2022/logo_var_orange_2_game_jam.png";
import logo_ctf from "../../../../src/posts/events/freshers2022/rhcs_ctf_general_branding_logo.svg";
import * as React from 'react';
export default {
  Section,
  EventSection,
  HeroSection,
  SiteLink,
  SiteButton,
  FreshersPictureHeroSection,
  freshers2020page,
  freshers2021table,
  freshers2020freshershacklogo,
  freshers2020freshershacklogolink,
  freshers2020capturetheflag,
  noBottom,
  freshers2021tableContainer,
  logo_var_orange,
  logo_ctf,
  React
};