// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-committee-index-js": () => import("./../../../src/pages/committee/index.js" /* webpackChunkName: "component---src-pages-committee-index-js" */),
  "component---src-pages-iframe-freshers-js": () => import("./../../../src/pages/iframe/freshers.js" /* webpackChunkName: "component---src-pages-iframe-freshers-js" */),
  "component---src-pages-iframe-hero-js": () => import("./../../../src/pages/iframe/hero.js" /* webpackChunkName: "component---src-pages-iframe-hero-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-events-index-jsx": () => import("./../../../src/templates/events/index.jsx" /* webpackChunkName: "component---src-templates-events-index-jsx" */),
  "component---src-templates-news-index-jsx": () => import("./../../../src/templates/news/index.jsx" /* webpackChunkName: "component---src-templates-news-index-jsx" */)
}

