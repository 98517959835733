// extracted by mini-css-extract-plugin
export var navbar = "HeroSection-module--navbar--lpkht";
export var navbarLight = "HeroSection-module--navbar-light--2xPOL";
export var bgCompsoc = "HeroSection-module--bg-compsoc--2edzQ";
export var navbarNav = "HeroSection-module--navbar-nav--1hpn9";
export var navLink = "HeroSection-module--nav-link--FjZle";
export var hero = "HeroSection-module--hero--3j0Xb";
export var btnGroup = "HeroSection-module--btn-group--2c7dq";
export var btn = "HeroSection-module--btn--1QknR";
export var fullscreen = "HeroSection-module--fullscreen--1Z413";
export var full = "HeroSection-module--full--3mXen";
export var half = "HeroSection-module--half--1ixya";
export var dark = "HeroSection-module--dark--2H0rm";
export var orange = "HeroSection-module--orange--1OWi6";
export var sectionTag = "HeroSection-module--section-tag--jBR5h";
export var link = "HeroSection-module--link--2x8Ft";
export var disabled = "HeroSection-module--disabled--9bslI";
export var media = "HeroSection-module--media---soo0";
export var news = "HeroSection-module--news--1AT9a";
export var description = "HeroSection-module--description--2kEsP";
export var links = "HeroSection-module--links--2EF_N";
export var events = "HeroSection-module--events--y-MyO";
export var event = "HeroSection-module--event--3alOr";
export var item = "HeroSection-module--item--DmnRX";
export var box = "HeroSection-module--box--2GD4r";
export var itemBody = "HeroSection-module--item-body--3ml9f";
export var icon = "HeroSection-module--icon--2ylIg";
export var name = "HeroSection-module--name--15FvR";
export var WhatWeDo = "HeroSection-module--WhatWeDo--3G2VK";
export var features = "HeroSection-module--features--GU4Kr";
export var team = "HeroSection-module--team--3vF3A";
export var card = "HeroSection-module--card--1dhzl";
export var cardContent = "HeroSection-module--card-content--2thK9";
export var cardPicture = "HeroSection-module--card-picture--wOOSY";
export var picture = "HeroSection-module--picture--22rhD";
export var footerText = "HeroSection-module--footer-text--2jy6v";
export var heroNameClass = "HeroSection-module--heroNameClass--2mTzE";